import { ApolloError } from '@apollo/client';
import { Box, Text, Input } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

interface IProps {
  name: string;
  type: string;
  placeholder?: string;
  handleChange: (e: any) => void;
  error?: ApolloError;
  width?: string;
  autoComplete?: string;
  passwordMatchError?: boolean;
  noMargin?: boolean;
}

const FormInput: FC<IProps> = ({
  name,
  type,
  placeholder,
  handleChange,
  error,
  width,
  autoComplete,
  passwordMatchError,
  noMargin
}) => {
  const [blur, setBlur] = useState(false);

  return (
    <Box mb={noMargin ? '0' : '10'}>
      <Input
        pl="0"
        _focus={{ border: 'none', borderBottom: '1px dotted' }}
        borderBottom="1px dotted"
        border="none"
        w={width}
        borderRadius="0"
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={handleChange}
        onBlur={() => setBlur(true)}
        isInvalid={error?.graphQLErrors.every(({ message }) => {
          if (message.includes('first_name')) {
            return true;
          }
        })}
      />
      {passwordMatchError && blur && (
        <Text color="red" mt={2} maxWidth="300px">
          Passwords do not match
        </Text>
      )}
      {error?.graphQLErrors?.length > 0 &&
        error.graphQLErrors.map(({ message }, index) => {
          if (message.includes(name)) {
            let errorMessage = JSON.parse(message)[name];
            if (errorMessage?.length > 0) {
              return (
                <Text color="red" mt={2} maxWidth="300px" key={index}>
                  {errorMessage[0]}
                </Text>
              );
            }
          }
        })}
    </Box>
  );
};

export default FormInput;
