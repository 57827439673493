import { Box, Button, Flex, Input, SimpleGrid, Center, Text, Link } from '@chakra-ui/react';
import Image from 'next/image';
import { Video } from 'cloudinary-react';
import { getCsrfToken } from 'next-auth/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import FormInput from '../../components/Commons/FormInput';
import { Mixpanel } from '../../utils/mixpanel';

export default function SignIn({ csrfToken }) {
  const router = useRouter();
  const { error, welcome } = router.query;
  const readableError = error
    ? typeof error !== 'string'
      ? JSON.parse(error.toString())
      : error
    : null;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    Mixpanel.track('Login', {});
  }, []);

  return (
    <SimpleGrid columns={2} h="100vh" display={{ base: 'block', md: 'grid' }}>
      <Center w="100%" h="100%">
        <Box>
          <Box mb={24}>
            <Image src={'/images/docent-logo.svg'} alt="Docent" width={175} height={45} />
          </Box>
          {welcome && (
            <>
              <Text fontSize="sm" color="docentLabel">
                Account created
              </Text>
              <Text mb={12} fontSize="xl">
                Welcome to Docent! Please sign in
              </Text>
            </>
          )}
          <form method="post" action="/api/auth/callback/credentials">
            <Flex direction="column">
              <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
              <FormInput
                name="username"
                autoComplete="username"
                type="email"
                placeholder="Email address"
                handleChange={(e) => setUsername(e.target.value)}
                width="300px"
              />
              <FormInput
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                handleChange={(e) => setPassword(e.target.value)}
                width="300px"
                noMargin
              />
              <Box mt={2} fontSize="xs" variant="docentLabel" textAlign="right">
                <Link href="/forgot_password">Forgot your password?</Link>
              </Box>

              {error && (
                <Text color="red">
                  {readableError['form']
                    ? readableError['form'][0]
                    : 'Something went wrong. Please try again.'}
                </Text>
              )}
              <Button
                disabled={!username || !password}
                variant="docentPrimary"
                width="300px"
                mt="10"
                type="submit">
                Continue
              </Button>
            </Flex>
          </form>
        </Box>
      </Center>
      <Center w="100%" h="100%" bg="black" display={{ base: 'none', md: 'inherit' }}>
        <Video
          playsInline
          secure={true}
          cloudName="docent-art"
          publicId="v1632495189/website/circle_y7gtgd"
          loop={true}
          autoPlay={true}></Video>
      </Center>
    </SimpleGrid>
  );
}

export async function getServerSideProps(context) {
  return {
    props: {
      csrfToken: await getCsrfToken(context)
    }
  };
}
